import React, { useState, useEffect , useRef  } from 'react';
import { Route, Routes } from 'react-router-dom';
import "./index.css";
import axios from 'axios';
import Navbar from './FrontEnd/Header/Navbar';
import Footer from './FrontEnd/Footer';
import Home from './FrontEnd/Home';
import Contact from './FrontEnd/Contact';
import Cart from './FrontEnd/Cart';
import Login from './FrontEnd/login';
import Product from './FrontEnd/Product';
import BlogUpload from './Admin/BlogsUpload';
import OrderManagement from './Admin/OrderManagement';
import CategoryForm from './Admin/categoryUpload';
import UploadManagement from './Admin/ProductManagement/UploadManagement';
import AdminPanel from './Admin/AdminPanel/AdminPanel';
import Blog from './FrontEnd/Blogs';
import DownloadPage from './DownloadPdf';
import CancellationPolicy from './Policies/CancellationPolicy';
import Disclaimer from './Policies/Disclaimer'
import PrivacyPolicy from './Policies/PrivacyPolicy'
import TermsAndConditions from './Policies/TermsAndConditions'
import SliderImage from './Admin/sliderimageupload/SliderImage'
import DetailsBlogs from './FrontEnd/Blogs/DetailsBlogs';
import AboutUs from '../src/AboutUs';
import NewsUpdate from './FrontEnd/NewsUpdate/Index';

function App() {
  const [blogs, setBlogs] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [countCartItems, setCountCartItems] = useState(0);
  const [cartMessage, setCartMessage] = useState('');
  const [productData, setProductData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [myorders, setOrders] = useState([]);
  const [clickedBlogId, setClickedBlogId] = useState(null);
  const cardElement = useRef(null);

  
  useEffect(() => {
  const fetchData = async () => {
    try {
      await Promise.all([getSliderData(), getCategories(), getProductData(),fetchBlogs()]);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error here, such as showing an error message to the user
    }
  };

  fetchData();
}, []);


  const getSliderData = () => {
    axios.get("https://waveart.in/image")
      .then((result) => {
        // Log the response content for inspection
        
        if (result.headers['content-type'].includes('application/json')) {
          setImageData(result.data.imageData);
        } else {
          console.log("Slider Data: Response is not JSON");
          // Handle non-JSON responses here
        }
      })
      .catch(error => {
        console.log("Slider Error:", error);
        // Handle any request errors here
      });
  };
  
  const onRemove = async (curElement) => {
    try {
      setCartItems(cartItems.filter((x) => x._id !== curElement._id));
      setCountCartItems(countCartItems - curElement.qty);
    } catch (error) {
      console.log(error, "Error removing product");
    }
  };

  const onAdd = async (item) => {
    const itemExist = cartItems.find((x) => x._id === item._id);
    if (itemExist) {
      setCartItems(
        cartItems.map((x) =>
          x._id === item._id ? { ...itemExist, qty: itemExist.qty + 1 } : x
        )
      );
    } else {
      setCartItems([...cartItems, { ...item, qty: 1 }]);
    }
    setCountCartItems(countCartItems + 1);
    setCartMessage("added to cart");

    try {
      const response = await axios.post("https://waveart.in/product", item);
      console.log("Product added successfully:", response.data);
      updateProductData(response.data); // Call the updateProductData function
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };
  const updateProductData = (newProduct) => {
    setProductData([...productData, newProduct]);
  };

  const getProductData = () => {
    axios

      .get("https://waveart.in/product")

      .then((result) => {
        setProductData(result.data.productData);
      })
      .catch(error => {
        console.log(error, "ProductData Error");
      });
  };
  const fetchBlogs = async () => {
    try {
      const response = await axios.get("https://waveart.in/BlogData", {
        headers: {
          'Cache-Control': 'no-cache' // Cache ko bypass karne ke liye Cache-Control: no-cache header ka istemal karein
        }
      });
      setBlogs(response.data);
    } catch (error) {
      console.error("Error fetching Blogs:", error);
    }
  };

  const getCategories = async () => {
    try {
      const response = await axios.get('https://waveart.in/categories');
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayNow = async (totalAmount, productIds, title, name, email, phone) => {
    try {
        // Generate unique user ID
        const userId = `USER_${name.replace(/\s/g, '_').toUpperCase()}_${Math.random().toString(36).substr(2, 9)}`;
        
        // Save user ID to session storage
        localStorage.setItem('userId', userId);
  
        // Send payment request along with user ID to the server
        const orderResponse = await axios.post("https://waveart.in/pay/RequestHandler", {
            userId: userId,
            amount: totalAmount,
            productIds: productIds,
            title: title,
            name: name,
            email: email,
            phone: phone
        });
  
        const orderData = orderResponse.data;
        const options = {
          key: "rzp_live_AnFII0wIWKwRLW",
            amount: totalAmount * 100, // Convert to paise
            currency: "INR",
            name: "DalalTechnologies", // Your business name
            description: "Test Transaction",
            image: "https://waveart.in:3000/Images/WAVEART.png",
            order_id: orderData.id,
            prefill: {
                name: name, // Your customer's name
                email: email,
                contact: phone // Provide the customer's phone number for better conversion rates
            },
            notes: {
                address: "Razorpay Corporate Office"
            },
          
            handler: async (response) => {
                console.log(response);
                const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
                console.log(razorpay_signature, "razorpay_signature hai ");
                try {
                    const verifyUrl = "https://waveart.in/pay/ResponseHandler";
                    const verificationResponse = await axios.post(verifyUrl, {
                        userId: userId,
                        razorpay_order_id,
                        razorpay_payment_id,
                        razorpay_signature,
                        productIds: productIds,
                        title: title,
                        name: name,
                        email: email,
                        phone: phone
                    });
                    // Set the purchased product ID in state
                    // setPurchasedProductId(productIds); // Replace with the actual product ID
                    setOrders(orderData); // Set the order data
                    // After payment is verified, navigate to the download page
                    window.location.href = "/download";
                } catch (error) {
                    console.log(error, "error occurred");
                }
            },
            theme: {
                color: "#121212",
            },
        };
  
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    } catch (error) {
        console.log(error);
    }
  };
// const fetchOrders = async () => {
//   try {
//       const ordersResponse = await axios.get('https://waveart.in/pay/getOrder');
//       const orders = ordersResponse.data;
//       // Set the fetched orders in the state
//       setOrders(orders);
//   } catch (error) {
//       console.error('Error fetching orders:', error);
//   }
// };
const handleBlogClick = (blogId) => {
  // Set the clicked blogId in state
  setClickedBlogId(blogId);
};

return (
  <div>
    <Navbar countCartItems={countCartItems} />
    <Routes>
      <Route path='/' element={<Home productData={productData} image={imageData} />} />
      <Route path='/Blogs' element={<Blog blogsData={blogs} onBlogClick={handleBlogClick} />} />
      <Route path="/details/:blogId" element={<DetailsBlogs />} />
      <Route path='/Contact' element={<Contact />} />
      <Route path="/cart" element={<Cart cartItems={cartItems} onRemove={onRemove} countCartItems={countCartItems} handlePayNow={handlePayNow} cardElement={cardElement} />} />
      <Route path="/Ignou-Solved-Assignment" element={<Product productData={productData} onAdd={onAdd} cartMessage={cartMessage} categories={categories} />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/adminPanel" element={<AdminPanel productData={productData}/>} />
      <Route path="/SliderImage" element={<SliderImage images={imageData} />} />
      <Route path="/BlogUpload" element={<BlogUpload blogsData={blogs} setBlogs={setBlogs} />} />
      <Route path="/UploadManagement" element={<UploadManagement productData={productData} categories={categories} updateProductData={updateProductData} />} />
      <Route path="/CategoryUpload" element={<CategoryForm categories={categories} />} />
      <Route path="/Order" element={<OrderManagement/>} />
      <Route path="/download" element={<DownloadPage productData={productData} />} />
      <Route path="/CancellationPolicy" element={<CancellationPolicy />} />
      <Route path="/Disclaimer" element={<Disclaimer />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/AboutUs" element={<AboutUs/>} />
      <Route path="/NewsUpdate" element={<NewsUpdate/>} />
    </Routes>
    <Footer />
    
  </div>
);
}

export default App;