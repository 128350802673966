import React, { useState, useEffect, useCallback } from 'react';
import Card from '../Card';
import './Product.css';
import NewsUpdate from '../NewsUpdate/Index';
// import Modal from "../Modal/Modal";

const Product = ({ productData, onAdd, categories }) => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(true);

  const filterProductsByCategory = useCallback((item) => {
    const targetCategory = item.toUpperCase();

    if (targetCategory === 'ALL COURSES') {
      setFilterProduct(productData);
      setShowMessage(false);
    } else {
      const result = productData.filter((x) => x.Category.toUpperCase() === targetCategory);

      if (result.length > 0) {
        setFilterProduct(result);
        setShowMessage(false);
      } else {
        setFilterProduct([]);
        setShowMessage(true);
      }
    }
  }, [productData]);

  useEffect(() => {
    filterProductsByCategory('ALL COURSES');
  }, [filterProductsByCategory]);

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);
    if (selectedCategory === 'Choose Your Category') {
      setFilterProduct(productData);
      setShowMessage(false);
    } else {
      filterProductsByCategory(selectedCategory);
    }
    setSearchInput('');
  };

  const handleSearchInputChange = (event) => {
    const searchValue = event.target.value;
    setSearchInput(searchValue);

    const filteredProducts = productData.filter(
      (product) =>
        product.Title.toLowerCase().includes(searchValue.toLowerCase()) ||
        product.Description.toLowerCase().includes(searchValue.toLowerCase()) ||
        product.Category.toLowerCase().includes(searchValue.toLowerCase())
    );

    setFilterProduct(filteredProducts);
    setShowMessage(filteredProducts.length === 0);

    if (filteredProducts.length > 0) {
      const firstProduct = filteredProducts[0];
      setSelectedCategory(firstProduct.Category.toUpperCase());
    } else {
      setSelectedCategory('');
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    // Redirect to the home page
  };

  return (
    <>
      <NewsUpdate />
      <div className='card-body'>
        <div className='product-input-body'>
          <input
            className='search-bar-input'
            type='text'
            placeholder='Search Your Subject'
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <div className='custom-select'>
            <select className='dropdown-list-container' value={selectedCategory} onChange={handleCategoryChange}>
              <option disabled hidden value=''>
                Choose Your Category
              </option>
              <option value='ALL COURSES'>All Courses</option>
              {categories.map((item) => (
                <option key={item.category} value={item.category} className='dropdown-title'>
                  {item.category}
                </option>
              ))}
            </select>
          </div>
        </div>
        {showMessage ? (
          <div className='message-container'>
            <img src='Images/giphy.gif' alt='Empty-Cart' className='Error-image' />
            <p className='message-text'>
              <h1>No products available.</h1>
            </p>
            <p className='sub-message'>
              <h2>Please check back later or explore other categories.</h2>
            </p>
          </div>
        ) : (
          <Card data={productData} onAdd={onAdd} filterProduct={filterProduct} />
        )}
      </div>
      {/* <Modal isVisible={isModalVisible} onClose={closeModal} /> */}
    </>
  );
};

export default Product;
