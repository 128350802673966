import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './adminnavbar.css';

const AdminNavbar = () => {
  const navigate = useNavigate();
  const [logoutTimeout, setLogoutTimeout] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/login', { replace: true });
    } else {
      startLogoutTimer();
      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('scroll', handleActivity);
      window.addEventListener('click', handleActivity);
    }

    return () => {
      clearTimeout(logoutTimeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, [navigate]);

  const startLogoutTimer = () => {
    clearTimeout(logoutTimeout); // Clear existing timeout
    const timeout = setTimeout(() => {
      logoutUser();
    }, 600000); // 10 minutes
    setLogoutTimeout(timeout);
  };

  const handleActivity = () => {
    startLogoutTimer();
  };

  const logoutUser = () => {
    localStorage.removeItem('token');
    navigate('/login', { replace: true });
  };

  return (
    <nav className="admin-navbar">
      <div className='admin-nav'>
        <NavLink className={({ isActive }) => `admin-nav-Link ${isActive ? 'active' : ''}`} to="/AdminPanel" title="Dashboard" onClick={handleActivity}>
          <i className="fas fa-tachometer-alt"></i>
        </NavLink>
        <NavLink className={({ isActive }) => `admin-nav-Link ${isActive ? 'active' : ''}`} to="/Order" title="Orders" onClick={handleActivity}>
          <i className="fas fa-box"></i>
        </NavLink>
        <NavLink className={({ isActive }) => `admin-nav-Link ${isActive ? 'active' : ''}`} to="/SliderImage" title="Slider Images" onClick={handleActivity}>
          <i className="fas fa-image"></i>
        </NavLink>
        <NavLink className={({ isActive }) => `admin-nav-Link ${isActive ? 'active' : ''}`} to="/BlogUpload" title="Blog Upload" onClick={handleActivity}>
          <i className="fas fa-blog"></i>
        </NavLink>
        <NavLink className={({ isActive }) => `admin-nav-Link ${isActive ? 'active' : ''}`} to="/UploadManagement" title="Upload Management" onClick={handleActivity}>
          <i className="fas fa-upload"></i>
        </NavLink>
        <NavLink className={({ isActive }) => `admin-nav-Link ${isActive ? 'active' : ''}`} to="/CategoryUpload" title="Category Upload" onClick={handleActivity}>
          <i className="fas fa-tags"></i>
        </NavLink>
        <button className="logout-button" onClick={logoutUser} title="Logout">
          <i className="fas fa-sign-out-alt"></i>
        </button>
      </div>
    </nav>
  );
};

export default AdminNavbar;
